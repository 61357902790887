/* eslint-disable max-len */
import React from 'react';

import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';
import { getLocale } from '../utils/locale';

const Accessibility = props => {
  return (
    <Layout location={props.location}>
      <div className="accessibility-page" role="main">
        <SectionContainer num={1} container>
          {getLocale() === 'fr' ? (
            <>
              <h2>D&eacute;claration sur l&rsquo;accessibilit&eacute; de Th&eacute;rapie Inkblot</h2>
              <div className="content-block">
                <p>Th&eacute;rapie Inkblot s&rsquo;engage &agrave; assurer l&rsquo;accessibilit&eacute; de ses produits num&eacute;riques et &agrave; se conformer aux <a href="https://www.essentialaccessibility.com/blog/web-content-accessibility-guidelines-wcag/">directives pour l&rsquo;accessibilit&eacute; des contenus Web</a>&nbsp;2.0, niveaux&nbsp;A et AA, et aux exigences en mati&egrave;re de communication efficace de la <a href="https://www.essentialaccessibility.com/blog/aoda-compliance/">Loi sur l&rsquo;accessibilit&eacute; pour les personnes handicap&eacute;es de l&rsquo;Ontario (AODA)</a>, <a href="https://www.tresor.gouv.qc.ca/fileadmin/PDF/ressources_informationnelles/AccessibiliteWeb/standard-access-web.pdf">des standards de l&rsquo;accessibilit&eacute; du Web au Qu&eacute;bec</a> (SGQRI 008 2.0), et &agrave; <a href="https://www.essentialaccessibility.com/blog/canadian-accessibility-laws">d&rsquo;autres r&egrave;glements applicables</a>.</p>
                <p>Pour y parvenir, nous avons &eacute;tabli un partenariat avec eSSENTIAL Accessibility, afin de g&eacute;rer notre programme d&rsquo;accessibilit&eacute; et de superviser sa gouvernance. Le programme d&rsquo;accessibilit&eacute; d&rsquo;eSSENTIAL Accessibility &eacute;value r&eacute;guli&egrave;rement nos produits num&eacute;riques conform&eacute;ment aux meilleures pratiques. Il est appuy&eacute; par une &eacute;quipe diversifi&eacute;e de professionnels de l&rsquo;accessibilit&eacute;, dont des utilisateurs de technologies d&rsquo;assistance. De plus, la plateforme va au-del&agrave; des exigences minimales de conformit&eacute; en mettant une <a href="https://www.essentialaccessibility.com/inkblottherapy?utm_source=inkblottherapyhomepage&amp;utm_medium=iconlarge&amp;utm_term=eachannelpage&amp;utm_content=header&amp;utm_campaign=inkblottherapy">application de technologie d&rsquo;assistance CX</a> &agrave; la disposition des clients qui ont de la difficult&eacute; &agrave; taper, &agrave; faire des gestes, &agrave; d&eacute;placer une souris ou &agrave; lire. L&rsquo;application peut &ecirc;tre t&eacute;l&eacute;charg&eacute;e gratuitement et comprend des outils comme des solutions de rechange &agrave; la souris et au clavier, la reconnaissance vocale, l&rsquo;activation vocale, la navigation mains libres et sans contact, et plus encore.</p>
                <p>Lorsque vous s&eacute;lectionnez un lien sur nos pages Web, qui m&egrave;ne &agrave; un site Web g&eacute;r&eacute; par un tiers, vous &ecirc;tes soumis aux politiques de confidentialit&eacute;, des droits d&rsquo;auteur, de s&eacute;curit&eacute; et de qualit&eacute; de l&rsquo;information de ce site Web, et nous ne pouvons pas garantir que ce dernier sera conforme aux directives pour l&rsquo;accessibilit&eacute; des contenus Web.</p>
                <p>N&rsquo;h&eacute;sitez pas &agrave; nous informer si vous rencontrez des obstacles li&eacute;s &agrave; l&rsquo;accessibilit&eacute; en utilisant nos produits num&eacute;riques. Veuillez communiquer avec notre soutien &agrave; la client&egrave;le &agrave; l&rsquo;adresse <a href="mailto:support@therapieinkblot.com">support@therapieinkblot.com</a>.</p>
              </div>
            </>
          ) : (
            <>
              <h2>Inkblot Therapy Accessibility Statement</h2>
              <div className="content-block">
                <p>Inkblot Therapy is committed to digital accessibility, and to conforming to the <a href="https://www.essentialaccessibility.com/blog/web-content-accessibility-guidelines-wcag/">Web Content Accessibility Guidelines (WCAG)</a> 2.0, Level A and AA and complying with the <a href="https://www.essentialaccessibility.com/blog/aoda-compliance/">Accessibility for Ontarians with Disabilities Act (AODA)</a> effective communication requirements, <a href="https://www.tresor.gouv.qc.ca/fileadmin/PDF/ressources_informationnelles/AccessibiliteWeb/standard-access-web.pdf">Qu&eacute;bec government Web accessibility standards (SGQRI 008 2.0)</a>, and <a href="https://www.essentialaccessibility.com/blog/canadian-accessibility-laws">other applicable regulations</a>.</p>
                <p>To accomplish this, we have partnered with eSSENTIAL Accessibility to administer our accessibility program and oversee its governance. Their accessibility program evaluates our digital products on an ongoing basis in accordance with best practices and is supported by a diverse team of accessibility professionals, including users of assistive technologies. The platform, moreover, goes beyond minimum compliance requirements by making an <a href="https://www.essentialaccessibility.com/inkblottherapy?utm_source=inkblottherapyhomepage&amp;utm_medium=iconlarge&amp;utm_term=eachannelpage&amp;utm_content=header&amp;utm_campaign=inkblottherapy">assistive CX technology application</a> available to customers who have trouble typing, gesturing, moving a mouse, or reading. The application is free to download and it incorporates tools such as mouse and keyboard replacements, voice recognition, speech enablement, hands-free/touch-free navigation, and more.</p>
                <p>When you select a link from our web pages, which leads to a third party managed website, you are subject to the privacy, copyright, security, and information quality policies of that website and we cannot guarantee that the website will comply with the Web Content Accessibility Guidelines (WCAG).</p>
                <p>We want to hear from you if you encounter any accessibility barriers on our digital properties. Please contact our Customer Support at <a href="mailto:support@inkblottherapy.com">support@inkblottherapy.com</a> .</p>
            </div>
            </>
          )}
        </SectionContainer>
      </div>
    </Layout>
  );
};

export default Accessibility;
